import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef } from "react";
import { DownloadStatusType, DownloadStatusTypeString } from "../../utilities/DownloadStatusType";
import { FlowTestResult } from "../../types/flowTestResult";
import { closestGraphTime } from "../../utilities/GraphUtilities";

Chart.register(annotationPlugin)

export const PumpChart = ({ chipflowFile, resultFile }: {chipflowFile: DownloadStatusType<FlowTestResult>, resultFile: DownloadStatusType<FlowTestResult>}) => {
    const chartContextRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (chipflowFile.type !== DownloadStatusTypeString.LOADED ||
            resultFile.type !== DownloadStatusTypeString.LOADED) {
            return
        }

        const data = resultFile.results

        let dataset = [
            {
                label: "Internal Flow Pump Pressure (mBar)",
                data: data.readings.map(item => item.flowPump),
                fill: false,
                tension: 0.1,
                yAxisID: 'y',
            },
            {
                label: "External Flow Meter flow rate * 10 (ml/s * 10)",
                data: data.readings.map(item => item.flowMeter * 10),
                fill: false,
                tension: 0.1,
                yAxisID: 'y',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                }
            }
        ]

        if (data.readings[0].degasserPump !== undefined) {
            dataset.push(
                {
                    label: "Internal Degasser Pump Pressure (mBar)",
                    data: data.readings.map(item => item.degasserPump!),
                    fill: false,
                    tension: 0.1,
                    yAxisID: 'y',
                }
            )
        }

        if (data.readings[0].flowEffort !== undefined) {
            dataset.push(
                {
                    label: "Internal Flow Pump Effort",
                    data: data.readings.map(item => item.flowEffort!),
                    fill: false,
                    tension: 0.1,
                    yAxisID: 'y',
                }
            )
        }

        if (data.readings[0].degasserEffort !== undefined) {
            dataset.push(
                {
                    label: "Internal Degasser Pump Effort",
                    data: data.readings.map(item => item.degasserEffort!),
                    fill: false,
                    tension: 0.1,
                    yAxisID: 'y',
                }
            )
        }

        const labels = data.readings.map(item => item.time)

        const chartData = {
            labels,
            datasets: dataset
        }

        const chartOptions = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: "Time (ms)",
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: "Pressure (mBar)",
                    },
                },
            },
            animation: {
                duration: 0,
            },
            plugins: {
                annotation: {
                    drawTime: "afterDraw",
                    annotations: Object.fromEntries(chipflowFile.results.stepTimes.map((item, index) => {
                        return [
                            index,
                            {
                                type: 'line',
                                scaleID: 'x',
                                value: closestGraphTime(labels, item.stepTime),
                                borderWidth: 1,
                                label: {
                                    display: true,
                                    content: index + 1,
                                    position: '0%',
                                    rotation: '0'
                                },
                            }
                        ]
                    }))
                }
            }
        };

        const chartContext = ((chartContextRef.current) as any).getContext("2d");
        chartRef.current = (new Chart(chartContext, {
            type: "line",
            data: chartData,
            options: chartOptions as any,
        }) as any)

        // Cleanup function to destroy the chart
        // and avoid errors of the form:
        // Canvas is already in use. Chart with ID '0' must be destroyed before the canvas with ID 'pressureChartRef' can be reused.
        return () => {
            if (chartRef.current) {
                (chartRef.current as any).destroy();
                chartRef.current = null;
            }
        }
    }, [chipflowFile, resultFile]);

    return <canvas id="chartRef" ref={chartContextRef} width={400} height={100} />
}
