import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const refractiveIndexChangeDetector = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    if (configuration.namedSpotRanges.length === 0) {
        throw new ValidationError("nameSpotRanges is empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "RefractiveIndexChangeDetector", {
        threshold: ["number", (value: any) => []],
        spotRange: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!configuration.namedSpotRanges.map((range) => range.name).includes(value)) {
                errors.push(new ValidationError(`spotRange "${value}" does not exist`))
            }
            return errors
        }]
    })

    return errors
}
