import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

/**
 * Checks that the spot indexes specified in the "refractiveIndexChangeFlowRateCalculator"
 * routine exist according to the sensorConfiguration.
 * @param configuration The configuration object.
 * @returns A list of validation errors if spot indexes are invalid.
 */
export const refractiveIndexChangeFlowRateCalculator = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    if (configuration.namedSpotRanges.length === 0) {
        throw new ValidationError("nameSpotRanges is empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "RefractiveIndexChangeFlowRateCalculatorStepRoutine", {
        firstSpotRange: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!configuration.namedSpotRanges.map((range) => range.name).includes(value)) {
                errors.push(new ValidationError(`spotRange "${value}" does not exist`))
            }
            return errors
        }],
        lastSpotRange: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!configuration.namedSpotRanges.map((range) => range.name).includes(value)) {
                errors.push(new ValidationError(`spotRange "${value}" does not exist`))
            }
            return errors
        }],
        threshold: ["number", (value: any) => []]
    })

    return errors;
};
