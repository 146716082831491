import { useEffect, useRef, useState } from "react";

import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { SelectField } from "@aws-amplify/ui-react";

import { DownloadStatusType, DownloadStatusTypeString } from "../../utilities/DownloadStatusType";
import { FlowTestResult, IntensityResult } from "../../types/flowTestResult";
import { applyChartTransforms, ChartTransforms, closestGraphTime } from "../../utilities/GraphUtilities";

Chart.register(annotationPlugin)

type IntensityChartProps = {
    chipflowFile: DownloadStatusType<FlowTestResult>
    resultFile: DownloadStatusType<[IntensityResult]>
    chartTransforms?: ChartTransforms
}

export const IntensityChart = ({ chipflowFile, resultFile, chartTransforms }: IntensityChartProps) => {
    const chartContextRef = useRef(null);
    const chartRef = useRef(null);

    const [referenceChannel, setReferenceChannel] = useState("")
    const [channels, setChannels] = useState<string[]>([])

    useEffect(() => {
        if (resultFile.type !== DownloadStatusTypeString.LOADED ||
            chipflowFile.type !== DownloadStatusTypeString.LOADED) {
            return
        }
        
        const data = resultFile.results

        let times = data.reduce<number[]>(
            (accumulator: number[], item) => {
                if (accumulator.length === 0 || accumulator[accumulator.length - 1] !== item.time) {
                    accumulator.push(item.time);
                }
                return accumulator
            },
            []
        )

        let channelData = data.reduce<{ [channel: string]: number[] }>((currentChannelData, entry) => {
            if (entry.channel in currentChannelData) {
                currentChannelData[entry.channel].push(entry.average)
            } else {
                currentChannelData[entry.channel] = [entry.average]
            }   
            return currentChannelData
        }, {})

        const channels = Object.keys(channelData)
        setChannels(channels)

        let stepTimes = chipflowFile.results.stepTimes.map((stepTime, index) => ({
            ...stepTime,
            stepNumber: index,
        }))

        if (chartTransforms) {
            ({channelData, times, stepTimes} = applyChartTransforms(
                { channelData, times, stepTimes },
                chartTransforms,
                { referenceChannel }))
        }

        const datasets = channels.map((channel) => {
            const averages = channelData[channel]
            return {
                label: channel,
                data: averages,
                fill: false,
                tension: 0.1,
                yAxisID: 'y',
            }
        })

        const chartData = {
            labels: times,
            datasets,
        }

        const chartOptions = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: "Time (ms)",
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: "Intensity (mIRU)",
                    },
                }
            },
            animation: {
                duration: 0,
            },
            plugins: {
                annotation: {
                    drawTime: "afterDraw",
                    annotations: Object.fromEntries(stepTimes
                        .map((item, index) => {
                            return [
                                index,
                                {
                                    type: 'line',
                                    scaleID: 'x',
                                    value: closestGraphTime(times, item.stepTime),
                                    borderWidth: 1,
                                    label: {
                                        display: true,
                                        content: item.stepNumber + 1,
                                        position: '0%',
                                        rotation: '0'
                                    },
                                }
                            ]
                        }))
                }
            }
        };

        const chartContext = ((chartContextRef.current) as any).getContext("2d");
        chartRef.current = (new Chart(chartContext, {
            type: "line",
            data: chartData,
            options: chartOptions as any,
        }) as any)

        return () => {
            if (chartRef.current) {
                (chartRef.current as any).destroy();
                chartRef.current = null;
            }
        }
    }, [chipflowFile, resultFile, referenceChannel, chartTransforms]);

    return <div>
        <canvas id="chartRef" ref={chartContextRef} width={400} height={100} />
        { chartTransforms?.referenceChannel && 
            <SelectField
                label="Reference Channel"
                value={referenceChannel}
                onChange={(e) => setReferenceChannel(e.target.value)}>
                { [""].concat(channels).map(channel =>
                    <option value={channel}>{ channel ? channel : "None" }</option>
                ) }
            </SelectField>
        }
    </div>
}
