import React, { useCallback } from 'react'
import { list, downloadData } from 'aws-amplify/storage';
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'
import { initiateUrlDownload } from '../utilities/DownloadUtilities';

export type SpotImage = {url: string, filename: string, captureTimestamp: string}

export type DownloadSpotImageOutput = DownloadStatusType<SpotImage[]>
export type DownloadAllSpotImages = () => void

/**
 * Download image items from the S3 bucket.
 * @param filePath Path to the folder.
 * @param filename A typical filename for a file in the experiment, complete with said timestamp.
 * @param itemPrefix The prefix of the image item to download.
 * @returns Image items with metadata.
 */
const downloadImageItems = async (filePath: string, filename: string, itemPrefix: string) => {
    const prefix = `${filePath}/${filename.replace("chipflowTest", itemPrefix)}`

    // Download all the files
    const filesListRequest = await list({prefix: prefix})
    const files = await Promise.all(filesListRequest.items.map(item => downloadData({ key: item.key }).result))
        .then(data => Promise.all(data.map(item => item.body.blob())))
        .then(blobs => blobs.map(blob => URL.createObjectURL(blob)))

    const fileListPackage: SpotImage[] = filesListRequest.items
        .map((item, index) => {
            // Extract metadata
            const filename = item.key.split("/").at(-1) ?? ""
            const captureTimestamp = filename
                // Extract capture timestamp
                ?.split("-").slice(7)
                // Display as timestamp
                .join(":")
                // Remove .png
                .split(".")[0]
                ?? ""

            return {
                url: files[index],
                filename,
                captureTimestamp,
            }
        })
    return fileListPackage
}

export const useSpotImages = (filePath: string, filename?: string): [DownloadSpotImageOutput, DownloadAllSpotImages] => {
    const [filesList, setFilesList] = React.useState<DownloadSpotImageOutput>({type: DownloadStatusTypeString.LOADING})

    const fetchFileList = useCallback(async () => {
        if (!filename) {
            setFilesList({type: DownloadStatusTypeString.ERROR, errorString: "filename was undefined"})
            return
        }

        setFilesList({type: DownloadStatusTypeString.LOADING})
        try {
            const spotImages = await downloadImageItems(filePath, filename, "spotsImage")
            const spotImagesPatterns = await downloadImageItems(filePath, filename, "spotsImagePattern")
            
            setFilesList({type: DownloadStatusTypeString.LOADED, results: spotImages.concat(spotImagesPatterns)})
        } catch (error: any) {
            setFilesList({type: DownloadStatusTypeString.ERROR, errorString: error.toString()})
        }
    }, [filePath, filename])

    React.useEffect(() => {
        fetchFileList()
    }, [fetchFileList])

    const downloadAllSpotImages = useCallback(() => {
        if (filesList.type === DownloadStatusTypeString.LOADED) {
            filesList.results.forEach(spotImage => {
                initiateUrlDownload(spotImage.url, spotImage.filename)
            })
        }
    }, [filesList])

    return [filesList, downloadAllSpotImages]
}