import { downloadData } from 'aws-amplify/storage';
import { FlowTestResult, IntensityResult, PersistedVariablesStep } from '../types/flowTestResult'
import { SpotsTime, SpotsTimeNormalised } from '../types/spotsTimeSeries'
import { DownloadStatusType, DownloadStatusTypeString } from './DownloadStatusType';

export const downloadJsonFileFromS3 = async<T, > (filename: string): Promise<T> => {
    try {
        const text = await downloadTextFileFromS3(filename)
        const json = JSON.parse(text)
        return json as T
    } catch (error: any) {
        console.error("error: ", error)
        throw error
    }   
}

export const downloadTextFileFromS3 = async (filename: string): Promise<string> => {
    try {
        const downloadResult = await downloadData({key: filename }).result
        const text = await downloadResult.body.text() // body.json() resulted in error: "parsing response to JSON is not implemented. Please use response.text()"
        return text
    } catch (error: any) {
        console.error("error: ", error)
        throw error
    } 
}

export const initiateOneSecondTimeSeriesDownload = async (filename: string, path: string) => {
    const spotsFilename = filename.replace("chipflowTest", "spotsOneSecondTimeSeries");

    const fileContents = await downloadTextFileFromS3(`${path}/${spotsFilename}.json`)
    initiateDataDownload(fileContents, `${spotsFilename}.json`, "application/json") 
}

export const initiateLoggingFileDownload = async (filename: string, path: string) => {
    const loggingFilename = filename.replace("chipflowTest", "logMessages");
    const s3path = `${path}/${loggingFilename}.tsv`
    const fileContents = await downloadTextFileFromS3(s3path)
    initiateDataDownload(fileContents, loggingFilename, "text/tab-separated-values") 
}

export const initiateVariablesTimeSeriesDownload = async (filename: string, path: string) => {
    const variablesFilename = filename.replace("chipflowTest", "chipflowTestVariables");

    const fileContents = await downloadTextFileFromS3(`${path}/${variablesFilename}.json`)
    initiateDataDownload(fileContents, `${variablesFilename}.json`, "application/json") 
}

export const initiateSpotFramesDownload = async (filename: string, path: string) => {
    const spotFramesFilename = filename.replace("chipflowTest", "spotsFrames");

    const fileContents = await downloadTextFileFromS3(`${path}/${spotFramesFilename}.json`)
    initiateDataDownload(fileContents, `${spotFramesFilename}.json`, "application/json") 
}

export const initiateDryWetTransitionDownload = async (filename: string, path: string) => {
    const fixedFilename = filename.replace("chipflowTest", "dryWetTransitionTimeSeries");

    const fileContents = await downloadTextFileFromS3(`${path}/${fixedFilename}.json`)
    initiateDataDownload(fileContents, `${fixedFilename}.json`, "application/json") 
}

export const initiateConfigurationDownload = async (path: string) => {
    const fileContents = await downloadTextFileFromS3(path)
    const parts = path.split('/');
    const filename = parts[parts.length - 1];
    initiateDataDownload(fileContents, filename, "application/json") 
}

export const initiateSpotsOneSecondTimeSeriesCSVDownload = async (filename: string, path: string) => {
    const spotsFilename = filename.replace("chipflowTest", "spotsOneSecondTimeSeries");
    const fileContents: SpotsTime[] = await downloadJsonFileFromS3(`${path}/${spotsFilename}.json`)
    const csvString = createTimeSeriesCSV(fileContents, ",")
    initiateDataDownload(csvString, `${filename}.csv`)
}

const createTimeSeriesCSV = (spotsTimeSeries: SpotsTime[], separator: string) : string => {
    let headerColumns = ["Time","r","g","b"]

    const rowLines = spotsTimeSeries.map(reading => {
        let rowData = [`${reading.time}`,`${reading.spots[0].r}`,`${reading.spots[0].g}`,`${reading.spots[0].b}`]
        return rowData.join(separator)
    })
    return headerColumns.join(separator) + "\n" + rowLines.join("\n")
}

export const initiateCombinedCSVDownload = async (filename: string, channelAverageIntensities: DownloadStatusType<[IntensityResult]>, path: string) => {
    // Ignore if not loaded, no feedback needed as button should already be disabled
    if (channelAverageIntensities.type !== DownloadStatusTypeString.LOADED) {
        return
    }

    // Get variables per frame
    const variablesFilename = filename.replace("chipflowTest", "chipflowTestVariables");
    const variablesSeries: PersistedVariablesStep[] = await downloadJsonFileFromS3(`${path}/${variablesFilename}.json`)

    // Get spots per frame
    const spotFramesFilename = filename.replace("chipflowTest", "spotsFrames");
    const spotFrames: SpotsTimeNormalised[] = await downloadJsonFileFromS3(`${path}/${spotFramesFilename}.json`)

    const csvString = createCombinedCSV(variablesSeries, spotFrames, channelAverageIntensities.results)

    // Trigger download for user
    const downloadFilename = filename.replace("chipflowTest", "combined")
    initiateDataDownload(csvString, `${downloadFilename}.csv`)
}

const createCombinedCSV = (variablesSeries: PersistedVariablesStep[], spotFrames: SpotsTimeNormalised[], channelAverageIntensities: [IntensityResult]): string => {
    // Adjust times to the same as the other data sets
    const variablesSeriesAdjusted = variablesSeries.map(variable => {
        return {
            ...variable,
            time: Math.round(variable.time * 1000)
        }
    })
    const spotFramesAdjusted = spotFrames.map(spotFrame => {
        return {
            ...spotFrame,
            time: Math.round(spotFrame.time * 1000)
        }
    })
    const channelAverageIntensitiesAdjusted = channelAverageIntensities.reduce((map, intensity) => {
        let channelAverages = map[intensity.time] ?? {}
        channelAverages[intensity.channel] = intensity.average
        map[intensity.time] = channelAverages
        return map
    }, {} as {[time: number]: {[channel: string]: number}})
    
    // Merge on time
    let combinedTimesSet = new Set(
        variablesSeriesAdjusted.map(reading => reading.time)
        .concat(spotFramesAdjusted.map(reading => reading.time))
        .concat(Object.keys(channelAverageIntensitiesAdjusted).map(x => parseInt(x)))
    )
    let combinedTimesArray = Array.from(combinedTimesSet).sort((a,b) => a-b)

    const variableNames = Object.keys(variablesSeriesAdjusted.at(-1)?.flowPressure.variables ?? {})
    const spotNormHeadings = spotFramesAdjusted.at(-1)?.spots.map((_, index) => `spot_norm_${index + 1}`) ?? []
    const channels = Object.keys(channelAverageIntensitiesAdjusted[parseInt(Object.keys(channelAverageIntensitiesAdjusted)[0])])

    const headings = [
        "Time",
    ]
    .concat(spotNormHeadings)
    .concat(variableNames)
    .concat(channels)

    let variablesTimeMap = Object.fromEntries(variablesSeriesAdjusted.map(reading => [reading.time, reading]))
    let spotsFrameTimeMap = Object.fromEntries(spotFramesAdjusted.map(reading => [reading.time, reading]))

    let rows = combinedTimesArray.map(time => {
        let variablesReading = variablesTimeMap[time]
        let spotsFrameReading = spotsFrameTimeMap[time]
        let channelAverages = channelAverageIntensitiesAdjusted[time]

        const row: any[] = [
            time
        ]

        if (spotsFrameReading) {
            const spots = spotsFrameReading.spots
            const space = spotNormHeadings.length - spots.length
            row.push(...spots)
            for (let i = 0; i < space; i++) {
                row.push("")
            }
        } else {
            for (let i = 0; i < spotNormHeadings.length; i++) {
                row.push("")
            }
        }

        if (variablesReading) {
            variableNames.forEach(variableName => {
                row.push(variablesReading.flowPressure.variables[variableName] ?? "")
            })
        } else {
            variableNames.forEach(_ => {
                row.push("")
            })
        }

        if (channelAverages) {
            channels.forEach(channel => {
                row.push(channelAverages[channel] ?? "")
            })
        }

        return row.join(",")
    })

    return `${headings.join(",")}\n` + rows.join("\n")
}

export const initiateOverviewCSVDownload = async (flowResults: FlowTestResult, filename: string) => {  
    const csvString = createFlowResultsCSV(flowResults, ',')
    initiateDataDownload(csvString, filename)
}

const createFlowResultsCSV = (flowResults: FlowTestResult, separator: string) : string => {
    let headerColumns = ["Time","Flow Meter","Flow Pump Pressure"]
    if (flowResults.readings[0].degasserPump !== undefined) {
        headerColumns.push("Degasser Pump Pressure")
        headerColumns.push("Flow Pump Effort")
        headerColumns.push("Degasser Pump Effort")
    }
    const rowLines = flowResults.readings.map(reading => {
        let rowData = [`${reading.time}`,`${reading.flowMeter}`,`${reading.flowPump}`]
        if (reading.degasserPump !== undefined) {
            rowData.push(`${reading.degasserPump}`)
            rowData.push(`${reading.flowEffort}`)
            rowData.push(`${reading.degasserEffort}`)
        }
        return rowData.join(separator)
    })
    return headerColumns.join(separator) + "\n" + rowLines.join("\n")
}

/**
 * Initiates the download of an arbitrary target variable.
 * @param obj The object to initiate a file download of.
 * @param baseFilename The base file name that contains the word 'chipflowTest' we will substitute.
 * @param desiredFilename The name to substitute for 'chipflowTest' in filename.
 */
export const initiateVariableDownload = (obj: object, baseFilename: string, desiredFilename: string) => {
    const stringObj = JSON.stringify(obj)
    const filename = `${baseFilename.replace("chipflowTest", desiredFilename)}.json`
    initiateDataDownload(stringObj, filename, "application/json")
}

// inspired by: js-file-download
// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
// used for download links
export const initiateDataDownload = (data: string | Blob, filename: string, mimeType: string = 'application/vnd.ms-excel') => {
    const blob = (typeof data === 'string') 
        ? new Blob([data], {type: mimeType}) 
        : data;
    const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    initiateUrlDownload(blobURL, filename)
}

export const initiateUrlDownload = (url: string, filename: string) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
  
    document.body.appendChild(tempLink);
    tempLink.click();
  
    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
    }, 200)
}
