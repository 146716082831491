import { StepInfo } from "../types/flowTestResult"

/**
 * Find the index of the closest time to a requested time on a graph.
 * 
 * Prefers earlier times if the difference is less than 250ms
 * to prevent step markers appearing on graph dips.
 * 
 * Useful because chart.js requires an index for step markers
 * instead of raw x points. Can be useful to sync data points
 * with slightly mismatching timestamps.
 * @param times Times, sorted in ascending order.
 * @param time
 * @returns The index of the closest time.
 */
export function closestGraphTime(times: number[], time: number) {
    for (let i = 0; i < times.length; i++) {
        if (times[i] > time && i > 0) {
            // Choose most accurate result preferring earlier times
            if (times[i] - time < 250) {
                return i
            } else {
                return i - 1
            }
        } else if (times[i] === time) {
            return i
        } else {
            continue
        }
    }
    return times.length - 1
}

/**
 * Specify what transforms you want to apply.
 */
export type ChartTransforms = {
    startFromWet: boolean
    normalize: boolean
    referenceChannel: boolean
}

/**
 * Data that some transforms require.
 */
export type ChartTransformsData = {
    referenceChannel?: string
}

/**
 * A step that also includes its step number in the configuration.
 */
export type AugmentedStepInfo = StepInfo & {
    stepNumber: number
}

/**
 * Data representing a chart.
 */
export type ChannelChart = {
    channelData: { [channel: string]: number[] }
    times: number[]
    stepTimes: AugmentedStepInfo[]
}

/**
 * Finds the baseline step in the chart.
 * 
 * The baseline step is the second instance of the "CaptureSpots" routine.
 * @param chart The data representing the chart.
 * @returns The baseline step, augmented with its step number in the configuration.
 */
function findBaselineStep(chart: ChannelChart): AugmentedStepInfo | null {
    const captureSpotsRoutines = chart.stepTimes.flatMap((item) =>
        item.step.routines?.some((routine) => routine.routineName === "CaptureSpots") ? item : []
    )
    
    if (captureSpotsRoutines.length > 2) {
        console.error("Start from wet failed, there were less than 2 capture spots routines")
        return null
    }
    
    const baselineStep = captureSpotsRoutines[1]

    if (!baselineStep) {
        console.error("Start from wet failed, failed to find a baseline capture spots routine")
        return null
    }

    return baselineStep
}

/**
 * Transforms the chart by starting all data from the baseline step.
 * @param chart The data representing the chart.
 * @param baselineStep A step identified as the baseline step.
 * @returns A transformed chart that starts from the baseline step.
 */
function startFromWetTransform(chart: ChannelChart, baselineStep: AugmentedStepInfo): ChannelChart {
    // Start 1 second after wet to avoid spike
    const baselineStepStartTimeIndex = chart.times.findIndex((time) => time >= baselineStep.stepTime + 1000)

    const finalStep = chart.stepTimes.at(-1)!
    const finalStepTimeIndex = chart.times.findIndex((time) => time >= finalStep.stepTime)
    const times = chart.times.slice(baselineStepStartTimeIndex, finalStepTimeIndex)

    // Start data at baseline step
    let channelData = {...chart.channelData}
    for (const channel in channelData) {
        channelData[channel] = channelData[channel].slice(baselineStepStartTimeIndex, finalStepTimeIndex)
    }
    let stepTimes = chart.stepTimes
        .filter((stepTime) => stepTime.stepTime >= baselineStep.stepTime)
        .filter((stepTime) => stepTime.stepTime <= finalStep.stepTime)

    return {times, channelData, stepTimes}
}

/**
 * Transforms the chart by normalizing all channels against a 1 second average
 * of itself starting from baseline step.
 * @param chart The data representing the chart.
 * @param baselineStep A step identified as the baseline step.
 * @returns A transformed chart that is normalized.
 */
function normalizeTransform(chart: ChannelChart, baselineStep: AugmentedStepInfo): ChannelChart {
    // Normalize all channels against a 1 second average of itself from baseline step
    const oneSecondOffBaseline = baselineStep.stepTime + 1000
    const oneSecondOffBaselineStepTimeTimeIndex = chart.times.findIndex((time) => time >= oneSecondOffBaseline)
    const oneSecondOffBaselineStepTimeTime = chart.times.find((time) => time >= oneSecondOffBaseline)

    if (!oneSecondOffBaselineStepTimeTime) {
        console.error("Failed normalize, could not find a time entry 1 second off baseline step")
        return chart
    }

    let oneSecondOffsetTimeIndex = chart.times.findIndex((time) => time - oneSecondOffBaselineStepTimeTime >= 1000)

    const channelData: { [channel: string]: number[] } = {}
    
    for (const channel in chart.channelData) {
        let channelWindowAverage = 0

        // Capture 1 second worth of average between indices
        const windowEndIndex = oneSecondOffsetTimeIndex + 1
        const oneSecondSlice = chart.channelData[channel].slice(oneSecondOffBaselineStepTimeTimeIndex, windowEndIndex)

        for (const number of oneSecondSlice) {
            channelWindowAverage += number
        }
        channelWindowAverage /= oneSecondSlice.length

        channelData[channel] = chart.channelData[channel].map((number) => number - channelWindowAverage)
    }

    return {times: chart.times, channelData, stepTimes: chart.stepTimes}
}

/**
 * Transforms the chart by subtracting the reference channel from all other channels.
 * @param chart The data that represents the chart.
 * @param referenceChannel The name of the reference channel. Must be a key in channelData.
 * @returns Transformed chart with all channels subtracted by the reference channel.
 */
function referenceChannelTransform(chart: ChannelChart, referenceChannel: string): ChannelChart {
    const channelData: { [channel: string]: number[] } = {}
    const referenceChannelData = chart.channelData[referenceChannel]

    for (const channel in chart.channelData) {
        channelData[channel] = chart.channelData[channel].map((number, index) => number - referenceChannelData[index])
    }

    return {times: chart.times, channelData, stepTimes: chart.stepTimes}
}

/**
 * Applys relevant chart transforms to a chart.
 * @param chart The data that represents the chart.
 * @param chartTransforms Which transforms you want to apply.
 * @param data Any data that transforms require.
 * @returns Transformed chart.
 */
export function applyChartTransforms(chart: ChannelChart, chartTransforms: ChartTransforms, data: ChartTransformsData): ChannelChart {
    let resultChart: ChannelChart = chart
    let baselineStep: AugmentedStepInfo | null = null

    if (chartTransforms.startFromWet) {
        baselineStep = baselineStep ?? findBaselineStep(chart)
        if (baselineStep) {
            resultChart = startFromWetTransform(resultChart, baselineStep)
        }
    }

    if (chartTransforms.normalize) {
        baselineStep = baselineStep ?? findBaselineStep(chart)
        if (baselineStep) {
            resultChart = normalizeTransform(resultChart, baselineStep)
        }
    }

    if (chartTransforms.referenceChannel) {
        if (data.referenceChannel) {
            resultChart = referenceChannelTransform(resultChart, data.referenceChannel)
        }
    }

    return resultChart
}
